import axios from '@/plugins/request'

export default {

  vlFront(vlImgUrl) {
    return axios({
      url: '/ocr/vlFront',
      method: 'GET',
      params: { vlImgUrl }
    })
  }

}
