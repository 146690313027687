import COS from 'cos-js-sdk-v5'
import Exif from 'exif-js'
import axios from '@/plugins/request'

const preUpload = function(fileName) {
  return axios({
    url: '/cos/preUpload',
    method: 'GET',
    params: {
      fileName: fileName
    }
  })
}

/**
 * 压缩调整图片方向，返回DataUrl。
 * @param file
 * @param maxWH
 * @returns {Promise<unknown>} imageDataUrl
 */
const imgCompress = function(file, maxWH) {
  return new Promise((resolve, reject) => {
    Exif.getData(file, function() {
      const orientation = Exif.getTag(this, 'Orientation')
      resolve(orientation)
    })
  }).then((orientation) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        const img = new Image()
        img.onload = function() {
          // 调整方向。
          const canvas1 = document.createElement('canvas')
          const ctx1 = canvas1.getContext('2d')
          if (orientation === 3 || orientation === 6 || orientation === 8) {
            switch (orientation) {
              case 3: // 旋转180°
                canvas1.width = img.width
                canvas1.height = img.height
                ctx1.rotate((180 * Math.PI) / 180)
                ctx1.drawImage(img, -img.width, -img.height, img.width, img.height)
                break
              case 6: // 旋转90°
                canvas1.width = img.height
                canvas1.height = img.width
                ctx1.rotate((90 * Math.PI) / 180)
                ctx1.drawImage(img, 0, -img.height, img.width, img.height)
                break
              case 8: // 旋转-90°
                canvas1.width = img.height
                canvas1.height = img.width
                ctx1.rotate((-90 * Math.PI) / 180)
                ctx1.drawImage(img, -img.width, 0, img.width, img.height)
                break
            }
          } else {
            canvas1.width = img.width
            canvas1.height = img.height
            ctx1.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas1.width, canvas1.height)
          }
          // 压缩大小
          const scaleX = maxWH / canvas1.width
          const scaleY = maxWH / canvas1.height
          const canvas2 = document.createElement('canvas')
          const ctx2 = canvas2.getContext('2d')
          if (scaleX < scaleY) {
            canvas2.width = maxWH
            canvas2.height = maxWH * (canvas1.height / canvas1.width)
          } else {
            canvas2.height = maxWH
            canvas2.width = maxWH * (canvas1.width / canvas1.height)
          }
          ctx2.drawImage(canvas1, 0, 0, canvas1.width, canvas1.height, 0, 0, canvas2.width, canvas2.height)
          img.w = canvas2.width
          img.h = canvas2.height
          img.data = canvas2.toDataURL('image/jpeg', 0.7)
          resolve(img.data)
        }
        img.src = this.result
      }
    })
  })
}

/**
 * 调整图片方向
 * @param file
 * @returns {Promise<unknown>} 返回 imageDataUrl格式
 */
const adjustOrientation = function(file) {
  return new Promise((resolve, reject) => {
    Exif.getData(file, function() {
      const orientation = Exif.getTag(this, 'Orientation')
      resolve(orientation)
    })
  }).then((orientiation) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        const orientation = orientiation
        const img = new Image()
        img.onload = function() {
          const canvas1 = document.createElement('canvas')
          const ctx1 = canvas1.getContext('2d')
          if (orientation === 3 || orientation === 6 || orientation === 8) {
            switch (orientation) {
              case 3: // 旋转180°
                canvas1.width = img.width
                canvas1.height = img.height
                ctx1.rotate((180 * Math.PI) / 180)
                ctx1.drawImage(img, -img.width, -img.height, img.width, img.height)
                break
              case 6: // 旋转90°
                canvas1.width = img.height
                canvas1.height = img.width
                ctx1.rotate((90 * Math.PI) / 180)
                ctx1.drawImage(img, 0, -img.height, img.width, img.height)
                break
              case 8: // 旋转-90°
                canvas1.width = img.height
                canvas1.height = img.width
                ctx1.rotate((-90 * Math.PI) / 180)
                ctx1.drawImage(img, -img.width, 0, img.width, img.height)
                break
            }
          } else {
            canvas1.width = img.width
            canvas1.height = img.height
            ctx1.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas1.width, canvas1.height)
          }
          resolve(canvas1.toDataURL('image/jpeg', 0.8))
        }
        img.src = this.result
      }
    })
  })
}

/**
 *
 * @param params {bucket, region, key, data[File|Blob|DataUrl]}
 * @param onProgress
 * @returns {Promise<T>}
 */
const upload = function(params) {
  return new Promise((resolve, reject) => {
    if (typeof params.data === 'string') {
      params.data = dataURLtoBlob(params.data)
      resolve(params)
    } else {
      resolve(params)
    }
  }).then((params) => {
    return new Promise(function(resolve, reject) {
      const cos = new COS({
        getAuthorization: function(options, callback) {
          axios.get('/cos/getUpToken').then(rsp => {
            callback({
              TmpSecretId: rsp.data.credentials.tmpSecretId,
              TmpSecretKey: rsp.data.credentials.tmpSecretKey,
              XCosSecurityToken: rsp.data.credentials.sessionToken,
              ExpiredTime: rsp.data.expiredTime
            })
          })
        }
      })
      cos.putObject({
        Bucket: params.bucket,
        Region: params.region,
        Key: params.key,
        Body: params.data,
        ContentLength: params.data.size
      }, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  })
}

const dataURLtoBlob = function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

const CosApi = {
  upload: upload,
  compress: imgCompress,
  adjustOrientation: adjustOrientation,
  preUpload: preUpload
}

export default CosApi
