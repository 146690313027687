<template>
  <div class="ocr-vl">

    <div class="add-vl">
      <van-uploader :after-read="afterRead" :preview-image="false">
        <div v-if="!vlImg" class="select-img">
          <van-icon name="photo-o" />
          <span>上传行驶证图片</span>
        </div>
        <div v-if="!!vlImg" class="select-img">
          <van-image width="100%" height="100%" fit="contain" :src="vlImg.content" />
        </div>
      </van-uploader>
    </div>

    <div class="btns-col" style="padding-left:var(--mp);padding-right:var(--mp);">
      <van-button type="primary" :disabled="!vlImg" @click="doOcr"> 上传 & 识别 </van-button>
      <van-button icon="arrow-left" @click="goBack"> 返 回</van-button>
    </div>

    <div class="notice">
      <div class="notice-title">注意事项</div>
      <ol>
        <li>手机/相机需正对行驶证拍摄，且行驶证位置居于正中，所占面积不低于80%；</li>
        <li>不得涂改，需确保四边角完整，水印、文字、图片、证件号码等清晰可辨；</li>
        <li>如果反光，可将行驶证从卡套中取出，并关闭手机/相机闪光，避免强光源直射；</li>
        <li>如上传图片多次识别失败，系统将对用户进行限制，当天不能上传行驶证；</li>
      </ol>
    </div>
  </div>
</template>

<script>
import CosApi from '@/apis/CosApi'
import OcrApi from '@/apis/OcrApi'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      vlImg: undefined
    }
  },
  computed: {
    ...mapGetters(['userType'])
  },
  methods: {
    afterRead(item) {
      CosApi.compress(item.file, 1200).then(dataUrl => {
        item.content = dataUrl
        this.vlImg = item
      }).catch(err => {
        this.vlImg = undefined
        this.$toast.fail('读取错误!')
        console.error(err)
      })
    },
    goBack() {
      if (this.userType === 'STAFF') {
        this.$router.replace('/vl/mng')
      } else if (this.userType === 'CUST') {
        this.$router.replace('/cust-vl/mng')
      }
    },
    doOcr() {
      let cosUrl = ''
      this.$toast.loading({ message: '上传中...', forbidClick: true, duration: 0, overlay: true })
      CosApi.preUpload(this.vlImg.file.name).then(rsp => {
        cosUrl = rsp.originUrl
        return CosApi.upload({ bucket: rsp.bucket, region: rsp.region, key: rsp.key, data: this.vlImg.content })
      }).then(rsp => {
        this.$toast.loading({ message: '识别中...', forbidClick: true, duration: 0, overlay: true })
        return OcrApi.vlFront(cosUrl)
      }).then(rsp => {
        this.$router.replace({ path: '/ocr-result', query: { vl: JSON.stringify(rsp.data) }})
        this.$toast.clear(true)
      }).catch(err => {
        this.$toast.clear(true)
        this.$toast.fail(err.message || '网络错误！')
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>
.ocr-vl{
  padding-top: var(--mp);
}
.add-vl{
  margin: 0px var(--mp) var(--mp) var(--mp);
  display: flex;
  justify-content: center;
}
.select-img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-text-sub);
  height: calc(100vw * 0.618);
  width: calc(100vw - var(--mp) * 2 - 4px);
  background-color: white;
  border: 2px solid var(--color-border);
  border-radius: 5px;
}
.select-img > i{
  font-size: 2rem;
  margin-bottom: 1rem;
}
</style>
